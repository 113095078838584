import _export from "../internals/export";
import _arrayReduce from "../internals/array-reduce";
import _arrayMethodIsStrict from "../internals/array-method-is-strict";
import _engineV8Version from "../internals/engine-v8-version";
import _engineIsNode from "../internals/engine-is-node";
var $ = _export;
var $reduce = _arrayReduce.left;
var arrayMethodIsStrict = _arrayMethodIsStrict;
var CHROME_VERSION = _engineV8Version;
var IS_NODE = _engineIsNode;

// Chrome 80-82 has a critical bug
// https://bugs.chromium.org/p/chromium/issues/detail?id=1049982
var CHROME_BUG = !IS_NODE && CHROME_VERSION > 79 && CHROME_VERSION < 83;
var FORCED = CHROME_BUG || !arrayMethodIsStrict("reduce");

// `Array.prototype.reduce` method
// https://tc39.es/ecma262/#sec-array.prototype.reduce
$({
  target: "Array",
  proto: true,
  forced: FORCED
}, {
  reduce: function reduce(callbackfn /* , initialValue */) {
    var length = arguments.length;
    return $reduce(this, callbackfn, length, length > 1 ? arguments[1] : undefined);
  }
});
export default {};